@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
:root {
  --color-primary: #7388ec;
  --color-danger: #ff7782;
  --color-success: #41f1b6;
  --color-warning : #ffbb55;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-primary-variant: #5969b5;
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2 rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2 rem;

  --box-shadow: 0 2rem 3rem var(--color-light);
}

.dark-theme-variables {
  --color-background: #181a1e;
  --color-white: #202528;
  --color-dark: #edeffd;
  --color-dark-variant: #a3bdcc;
  --color-light: rgba(0, 0, 0, 0.4);
  --box-shadow: 0 2rem 3rem var(--color-light);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  appearance: none;
  border: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html{
  font-size: 14px;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: poppins, sans-serif;
  font-size: 0.88rem;
  background: var(--color-background);
  user-select: none;
  overflow-x: hidden;
  color: var(--color-dark);
}

a {
  color: var(--color-dark);
}

img {
  display: block;
  width: 100%;
}

h1 {
  font-weight: 800;
  font-size: 1.8rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 0.87rem;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.77rem;
}

small {
  font-size: 0.75rem;
}

.text-muted {
  color: var(--color-info-dark);
}

p {
  color: var(--color-dark-variant);
}

b{
  color: var(--color-dark);
}

.primary {
  color: var(--color-primary)
}

.danger {
  color: var(--color-danger)
}

.success {
   color: var(--color-success)
}

.warning {
    color: var(--color-warning)
}

.container {
  display: grid;
  width: 96%;
  margin: 0 auto;
  gap: 1.8rem;
  grid-template-columns: 14rem auto 14rem;
}

aside {
  height: 100vh;
}

aside .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;
}

aside .logo {
  display: flex;
  gap: 0.8rem;
}

aside .logo img {
  width: 2rem;
  height: 2rem;
}

aside .close {
  display: none;
}

aside .sidebar {
  display: flex;
  flex-direction: column;
  height: 86vh;
  position: relative;
  top: 3rem;
}

aside h3 {
  font-weight: 500;
}

aside .sidebar a{
  display: flex;
  color: var(--color-info-dark);
  margin-left: 2rem;
  gap: 1rem;
  align-items: center;
  position: relative;
  height: 3.7rem;
  transition: all 300ms ease;
}

aisde .sidebar a span {
  font-size: 1.6rem;
  transition: all 300ms ease;
}


aside .sidebar a.active {
  background: var(--color-light);
  color: var(--color-primary);
  margin-left: 0;
}

aside .sidebar a.active:before{
  content: '';
  width: 6px;
  height: 100%;
  background: var(--color-primary);
}

aside .sidebar a.active span{
  color: var(--color-primary);
  margin-left: calc(1rem - 3px);
}

aside .sidebar a.hover {
  color: var(--color-primary);
}

aside .sidebar a:hover span {
  margin-left: 1rem;
}

main {
  margin-top: 1.4rem;
}

main .insights{
  display: grid;
  width: 100%;
}

main .insights > div{
  background: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  margin-top: 1rem;
  box-shadow: var(--box-shadow);
}

main .insights > div span {
  background: var(--color-primary);
  padding: 0.5rem;
  border-radius: 50%;
  color: var(--color-white);
  font-size: 2rem;
}

main .insights > div .middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

main .insights h3 {
  margin: 1rem 0 0 0;
  font-size: 1rem;
}

main .insights h1 {
  margin: 1rem 0 0 0;
}

main .form {
  margin-top: 2rem;
  background: var(--color-white);
  width: 100%;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  text-align: left;
  box-shadow: var(--box-shadow);
}

main .form h2 {
  margin-bottom: 0.8rem;
}

main .form div {
  height: 2.8rem;
}

.right {
  margin-top: 1.4rem;

}

.right .top {
  display: flex;
  justify-content: right;
  gap: 2rem;
  backgroun: gray;
}

.right .top button {
  display: none;
}

.right .theme-toggler {
  backgound: var(--color-light);
  display: flex;
  justify-content: space-between;
  alighn-items: center;
  height: 1.6rem;
  width: 4.2rem;
  cursor: pointer;
  border-radius: var(--border-radius-1);
}

.right .theme-toggler span {
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right .theme-toggler span.active {
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: var(--border-radius-1);
}

@media screen and (max-width: 1200px) {
  .container {
    width: 94%;
    grid-template-columns: 7rem auto 7rem;
  }

  aside .logo h2 {
    display: none;
  }

  aside .sidebar h3 {
    display: none;
  }

  aside .sidebar a {
    width: 5.6rem;
  }


}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    grid-template-columns: 1fr;
  }

  aside .logo h2 {
    display: none;
  }

  aside .sidebar h3 {
    display: none;
  }

  aside .sidebar a {
    width: 5.6rem;
  }

  main{
    margin-top: 0.8rem;
    padding: 0.1rem;
  }

  main .form{
    position: relative;
    margin: 3rem 0 0 0;
    width: 100%
  }

  .right {
    width: 94%;
    margin: 0 auto 4rem;
  }

  .right .top {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    padding: 0 0.8rem;
    height: 4.6rem;
    background: var(--color-white);
    width: 100%;
    margin: 0;
    z-index: 2;
    box-shadow: 0 1rem 1rem var(--color-light);
  }

  .right .top .theme-toggler {
    width: 4.4rem;
    position: absolute;
    left: 66%;
  }

  .right .top button {
    display: inline-block;
    background: transparent;
    cursor: pointer;
    color: var(--color-dark);
    position: absolute;
    left: 1rem;
  }

  .right .top button span {
    font-size: 2rem;
  }

}

.input-field {
  margin-bottom: 1rem;
  display: flex;
}

.input-field label {
  width: 36rem;
  margin-right: 0.625rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-dark-variant);
}

.required::after{
  content: "*";
  color: red;
  font-size: 1rem;
}

.input-field input {
  width: 50%;
  outline: none;
  border: 0.06rem solid var(--color-primary);
  font-size: 0.87rem;
  padding: 0.5rem 0.625rem;
  border-radius: var(--border-radius-1);
  transition: all 0.3s ease;
  color: var(--color-dark-variant);
  background-color: var(--color-white);
}

.form-btn {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.submit-btn {
  width: 12rem;
  padding: 0.5rem 0.625rem;
  font-size: 1rem;
  border: 0;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: var(--border-radius-1);
  outline: none;
}

.input-field:last-child {
  margin-bottom: 0;
}

.submit-btn:hover {
  background: var(--color-primary-variant);
}